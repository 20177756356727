<template>
  <div
    v-if="user?.super_user"
    class="typo-caption fixed left-0 top-0 z-[999] flex h-5 w-full justify-between bg-[#E58686] px-4 text-white"
  >
    <div></div>
    <div>Logget ind som: {{ impersonatedCustomerName }}</div>
    <a :href="hubUrl">Tilbage til Hub</a>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUserStore } from "~/store/UserStore";
import { useOfficehub } from "~/utils/use-officehub";

const { impersonatedCustomerName } = useOfficehub();
const { user } = storeToRefs(useUserStore());
const hubUrl = useRuntimeConfig().public.hubUrl;
</script>
