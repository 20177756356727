<template>
  <div
    v-if="isOfficehubImpersonation"
    class="typo-body fixed left-0 top-0 z-[999] flex h-[40px] w-full items-center justify-between bg-semantic-info-600 px-4 font-medium text-white"
  >
    <div class="shrink-0 grow-0 basis-1/3 overflow-hidden text-white" />
    <div
      class="shrink-0 grow-0 basis-1/3 overflow-hidden text-center text-white"
    >
      {{ $t("officehub.viewing_as_name", { name: impersonatedCustomerName }) }}
    </div>
    <div
      class="shrink-0 grow-0 basis-1/3 cursor-pointer overflow-hidden text-right text-white"
      @click="close"
    >
      <div class="flex items-center justify-end gap-1">
        <BaseIcon
          v-if="!leaveImpersonationModeLoading"
          icon="cross"
          :size="11"
        />
        <BaseIcon v-else icon="loading" :size="16" class="animate-spin" />
        {{ $t("officehub.close") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "~/store/AuthStore";
import { useOfficehub } from "~/utils/use-officehub";
import { toast } from "~/utils/toast";
const customerAppUrl = useRuntimeConfig().public.customerAppUrl;

const leaveImpersonationModeLoading = ref(false);
const { setToken } = useAuthStore();
const {
  isOfficehubImpersonation,
  impersonatedCustomerName,
  leaveOfficehubImpersonationMode,
} = useOfficehub();

async function close() {
  if (leaveImpersonationModeLoading.value) return;

  leaveImpersonationModeLoading.value = true;

  let token;
  try {
    token = await leaveOfficehubImpersonationMode();
  } catch (e) {
    toast({
      type: "error",
      text: "Logging out of sub-account failed. Please go back to app.officeguru.dk and log out manually.",
    });
    leaveImpersonationModeLoading.value = false;
    return;
  }

  setToken(token);
  await nextTick();
  window.location.href = `${customerAppUrl}officehub/accounts`;
}
</script>
